import {createReducer} from '@reduxjs/toolkit'
import {ScheduleItem} from '@wix/ambassador-wix-events-agenda/types'
import {getSchedule, getBookmarks, createBookmark, deleteBookmark, getScheduleData} from '../actions/schedule'
import {ScheduleItemResponse, ScheduleResponse} from '../types'

const initialState: ScheduleState = {
  items: [],
  total: 0,
  availableLocations: [],
  availableTags: [],
  bookmarks: [],
  bookmarking: false,
}

export const schedule = createReducer(initialState, builder => {
  builder
    .addCase(getSchedule.fulfilled, (state, action) => updateStateWithSchedule(state, action.payload))
    .addCase(getScheduleData.fulfilled, (state, action) => updateStateWithSchedule(state, action.payload.schedule))
    .addCase(getBookmarks.fulfilled, (state, action) => {
      const bookmarks = action.payload.items

      state.bookmarks = bookmarks
      state.items = state.items.map(item => {
        item.bookmarked = Boolean(bookmarks.find(bookmark => bookmark.id === item.id))
        return item
      })
    })
    .addCase(createBookmark.pending, state => {
      state.bookmarking = true
    })
    .addCase(createBookmark.fulfilled, (state, action) => {
      const {itemId} = action.meta.arg
      const newBookmark = state.items.find(item => item.id === itemId)

      state.bookmarking = false
      state.bookmarks = [...state.bookmarks, newBookmark]
      state.items.forEach(item => {
        if (item.id === itemId) {
          item.bookmarked = true
        }
      })

      return state
    })
    .addCase(createBookmark.rejected, state => {
      state.bookmarking = false
    })
    .addCase(deleteBookmark.pending, state => {
      state.bookmarking = true
    })
    .addCase(deleteBookmark.fulfilled, (state, action) => {
      const {itemId} = action.meta.arg

      state.bookmarking = false
      state.bookmarks = state.bookmarks.filter(bookmark => bookmark.id !== itemId)
      state.items.forEach(item => {
        if (item.id === itemId) {
          item.bookmarked = false
        }
      })

      return state
    })
    .addCase(deleteBookmark.rejected, state => {
      state.bookmarking = false
    })
})

const updateStateWithSchedule = (state: ScheduleState, {items, total, facets}: ScheduleResponse) => {
  if (!state.total) {
    state.total = total
  }

  if (!state.availableLocations.length && !state.availableTags.length) {
    state.availableLocations = Object.keys(facets.stageName.counts)
    state.availableTags = Object.keys(facets.tag.counts)
  }

  state.items = items
}

export interface ScheduleState {
  items: ScheduleItemResponse[]
  total: number
  availableLocations: string[]
  availableTags: string[]
  bookmarks: ScheduleItem[]
  bookmarking: boolean
}
